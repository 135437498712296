const main = {
  'App Description':
    '串接成功後，SHOPLINE 系統將會紀錄功您在 SHOPBACK上的商店連結所引導過來顧客的訂單。在顧客結帳時，SHOPLINE 會自動將訂購資訊回傳給 SHOPBACK，訂單鑑賞期過後，SHOPLINE 會再次回報最終結算金額給 SHOPBACK 作為現金回饋的依據。',
  'Connect Shopback Cashback': '串接 SHOPBACK 商店導購',
  'Connect Shopback Caption': '請與你的 SHOPBACK 窗口聯絡，取得以下資訊',
  'Order offer ID': '訂單 offer 代號',
  'Validation offer ID': '驗證 offer 代號',
  'provided by Shopback': '{{ value }} (由SHOPBACK提供)',
  'Setting has been saved successfully': '已成功儲存設定',
  'Please enter an valid ID': '請輸入有效代號',
};

export default main;
