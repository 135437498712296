import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useNav = ({ e2ePrefix }) => {
  const { t } = useTranslation('main');
  const formSectionRef = useRef();
  const items = useMemo(
    () => [
      {
        title: t('Connect Shopback Cashback'),
        e2eId: `${e2ePrefix}-connect-shopback`,
        target: formSectionRef,
        content: t('Connect Shopback Caption'),
      },
    ],
    [t, e2ePrefix],
  );

  return {
    refs: {
      formSectionRef,
    },
    items,
  };
};

export default useNav;
