const main = {
  'App Description':
    'หลังจากเชื่อมต่อเสร็จแล้ว SHOPLINE จะบันทึกคำสั่งซื้อจากลูกค้าโดยตรงจากร้านค้าของคุณบน SHOPBACK  เมื่อลูกค้าทำการสั่งซื้อ SHOPLINE จะส่งข้อมูลการสั่งซื้อไปยัง SHOPBACK โดยอัตโนมัติ เมื่อสั่งซื้อจบแล้ว SHOPLINE จะส่งยอดสั่งซื้อสุดท้ายไปยัง SHOPBACK เพื่อทำการคำนวณเงินคืน',
  'Connect Shopback Cashback': 'เชื่อมต่อเงินคืนของ SHOPBACK',
  'Connect Shopback Caption':
    'กรุณาติดต่อ SHOPBACK ของคุณเพื่อรับข้อมูลด้านล่าง',
  'Order offer ID': 'รหัสข้อเสนอคำสั่งซื้อ',
  'Validation offer ID': 'รหัสข้อเสนอการตรวจสอบความถูกต้อง',
  'provided by Shopback': '{{ value }} (ที่สร้างโดย SHOPBACK)',
  'Setting has been saved successfully': 'บันทึกการตั้งค่าเรียบร้อยแล้ว',
  'Please enter an valid ID': 'โปรดกรอก ID ที่ถูกต้อง',
};

export default main;
