import { useState, useCallback, useMemo, useEffect } from 'react';
import update from 'immutability-helper';
import { curryRight, mapValues, transform } from 'lodash-es';
import validate from 'utils/validate';

const curriedUpdate = curryRight(update, 2);

const getFieldError = (field, value, rules) => {
  const fieldRule = rules[field];
  if (!fieldRule) {
    return '';
  }
  return validate(value, fieldRule);
};

const useSimpleForm = ({
  defaultValues,
  rules,
  validateDefaultValues,
  onSubmit,
}) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(mapValues(defaultValues, () => ''));
  const [touched, setTouched] = useState(false);
  const handlers = useMemo(
    () =>
      mapValues(defaultValues, (_, field) => ({
        onChange: (value) => {
          setValues(
            curriedUpdate({
              [field]: {
                $set: value,
              },
            }),
          );
          setErrors(
            curriedUpdate({
              [field]: {
                $apply: (message) =>
                  message === '' ? '' : getFieldError(field, value, rules),
              },
            }),
          );
        },
        onBlur: ({ target: { value } }) => {
          setTouched(true);
          const error = getFieldError(field, value, rules);
          setErrors(
            curriedUpdate({
              [field]: {
                $set: error,
              },
            }),
          );
        },
      })),
    [rules, defaultValues],
  );
  const { hasErrors } = transform(
    errors,
    (result, value, key) => {
      if (!result.hasErrors) {
        result.hasErrors = value !== '';
      }
    },
    { hasErrors: false },
  );

  const handleSubmit = useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  useEffect(() => {
    setTouched(false);
    if (validateDefaultValues) {
      setErrors(
        mapValues(defaultValues, (value, field) =>
          getFieldError(field, value, rules),
        ),
      );
    } else {
      setErrors(mapValues(defaultValues, () => ''));
    }
  }, [validateDefaultValues, defaultValues, rules]);

  return [values, { errors, handlers, handleSubmit, hasErrors, touched }];
};

export default useSimpleForm;
