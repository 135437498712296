import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import curry from 'lodash/curry';

export const pickShopbackConfig = ({ orderOfferId, validationOfferId }) => ({
  orderOfferId: orderOfferId || '',
  validationOfferId: validationOfferId || '',
});

const getShopbackConfig = async (merchantId) => {
  const { data } = await axios.get(`/api/merchants/${merchantId}`);
  return pickShopbackConfig(data);
};

const postShopbackConfig = async (merchantId, shopbackConfig) => {
  const { data } = await axios.post(
    `/api/merchants/${merchantId}`,
    shopbackConfig,
  );
  return pickShopbackConfig(data);
};

const curriedPostShopbackConfig = curry(postShopbackConfig, 2);

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [_, merchantId] }) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId) => ['shopback-config', merchantId];

export const useQueryShopbackConfig = () => {
  const { merchantId } = useParams();
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getShopbackConfig),
    {
      enabled: !!merchantId,
    },
  );
};

export const useMutateShopbackConfig = ({ onSuccess }) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedPostShopbackConfig(merchantId), {
    onSuccess: (...args) => {
      queryClient.setQueryData(composeQueryKey(merchantId), args[1]);
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};
