import cond from 'lodash/cond';
import iteratee from 'lodash/iteratee';
import stubTrue from 'lodash/stubTrue';
import constant from 'lodash/constant';
import identity from 'lodash/identity';
import prop from 'lodash/fp/prop';
import {
  LANGUAGE_CHANGE,
  REDIRECT_TO_INNER_PATH,
  REQUEST_ADMIN_HOST,
  REQUEST_APP_STORE_PATH,
} from 'constants/iframeMessageType';

const parseType = (type, parse) =>
  cond([
    [iteratee({ type }), (data) => ({ $set: parse(data) })],
    [stubTrue, constant({ $apply: identity })],
  ]);

const config = {
  languageChange: {
    initialValue: null,
    parse: parseType(LANGUAGE_CHANGE, prop(['payload', 'lang'])),
  },
  redirectToInnerPath: {
    initialValue: null,
    dispatch: (path) => ({ type: REDIRECT_TO_INNER_PATH, payload: { path } }),
  },
  requestAdminHost: {
    initialValue: '',
    dispatch: () => ({ type: REQUEST_ADMIN_HOST }),
    parse: parseType(REQUEST_ADMIN_HOST, prop(['payload', 'host'])),
  },
  requestAppStorePath: {
    initialValue: '',
    dispatch: () => ({ type: REQUEST_APP_STORE_PATH }),
    parse: parseType(REQUEST_APP_STORE_PATH, prop(['payload', 'path'])),
  },
};

export default config;
