const main = {
  'App Description':
    'By completing the connection, SHOPLINE will record the orders made from customers directed from SHOPBACK. When the customers place order, SHOPLINE sends the order information to Shopback; when the appreciation period of orders pass, SHOPLINE sends its final order amount to Shopback to do the calculation of cashbacks.',
  'Connect Shopback Cashback': 'Connect SHOPBACK Cashback',
  'Connect Shopback Caption':
    'Please find your SHOPBACK contact to get below information',
  'Order offer ID': 'Order offer ID',
  'Validation offer ID': 'Validation offer ID',
  'provided by Shopback': '{{ value }}(provided by SHOPBACK)',
  'Setting has been saved successfully': 'Setting has been saved successfully',
  'Please enter an valid ID': 'Please enter an valid ID',
};

export default main;
