const main = {
  'App Description':
    'Khi hoàn thành kết nối, SHOPLINE sẽ thống kê các đơn hàng khách hàng đặt từ SHOPBACK. Khi khách hàng đặt hàng, SHOPLINE sẽ gửi thông tin đơn hàng đến SHOPBACK, sau khi hoàn thành xác nhận đơn hàng, SHOPLINE sẽ gửi giá trị đơn hàng cuối cùng đến SHOPBACK để tính toán số tiền được hoàn.',
  'Connect Shopback Cashback': 'Kết nối ứng dụng hoàn tiền mua sắm SHOPBACK',
  'Connect Shopback Caption':
    'Vui lòng vào tài khoản SHOPBACK của bạn để lấy các thông tin sau đây',
  'Order offer ID': 'Mã ưu đãi đặt hàng',
  'Validation offer ID': 'Xác thực mã ưu đãi',
  'provided by Shopback': '{{ value }} (do SHOPBACK cung cấp)',
  'Setting has been saved successfully': 'Đã lưu cài đặt thành công',
  'Please enter an valid ID': 'Vui lòng nhập ID hợp lệ',
};

export default main;
