import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import useSimpleForm from 'hooks/useSimpleForm';
import ToastContext from 'contexts/Toast';
import { useMutateShopbackConfig } from './useShopbackConfig';

const OFFER_ID_PATTERN = /^\d{4}$/;

const useRules = () => {
  const { t } = useTranslation(['main', 'fieldValidation']);
  return useMemo(() => {
    const orderFieldName = t('provided by Shopback', {
      value: t('Order offer ID'),
    });
    const validationFieldName = t('provided by Shopback', {
      value: t('Validation offer ID'),
    });
    return {
      orderOfferId: {
        required: {
          value: true,
          message: t('required', {
            ns: 'fieldValidation',
            fieldName: orderFieldName,
          }),
        },
        pattern: {
          value: OFFER_ID_PATTERN,
          message: t('Please enter an valid ID'),
        },
      },
      validationOfferId: {
        required: {
          value: true,
          message: t('required', {
            ns: 'fieldValidation',
            fieldName: validationFieldName,
          }),
        },
        pattern: {
          value: OFFER_ID_PATTERN,
          message: t('Please enter an valid ID'),
        },
      },
    };
  }, [t]);
};

const useShopbackConfigForm = ({ defaultValues }) => {
  const { createUIToast } = useContext(ToastContext);
  const onSuccess = useCallback(
    (data) => {
      createUIToast({
        id: nanoid(),
        type: 'success',
        titleWithParams: {
          key: 'main:Setting has been saved successfully',
        },
      });
    },
    [createUIToast],
  );
  const { mutate, isLoading: submitting } = useMutateShopbackConfig({
    onSuccess,
  });
  const rules = useRules();
  const [values, meta] = useSimpleForm({
    defaultValues,
    rules,
    onSubmit: mutate,
  });

  const renderOrderMessages = useMemo(
    () => [() => meta.errors.orderOfferId],
    [meta.errors.orderOfferId],
  );
  const renderValidationMessages = useMemo(
    () => [() => meta.errors.validationOfferId],
    [meta.errors.validationOfferId],
  );

  return [
    values,
    meta,
    {
      submitting,
      renderMessages: {
        orderOfferId: renderOrderMessages,
        validationOfferId: renderValidationMessages,
      },
    },
  ];
};

export default useShopbackConfigForm;
