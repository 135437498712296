import { useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import PostMessageContext from 'contexts/PostMessage';

const composeUrl = (host, path) => (host && path && `${host}${path}`) || '';

const useBreadcrumb = ({ e2ePrefix }) => {
  const { t } = useTranslation('pageTitle');
  const [
    { requestAdminHost: adminHost, requestAppStorePath: appStorePath },
    { requestAdminHost, requestAppStorePath, redirectToInnerPath },
  ] = useContext(PostMessageContext);

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('Apps'),
        label: t('Apps'),
        e2eId: `${e2ePrefix}-apps`,
      },
      {
        id: t('App Store'),
        label: t('App Store'),
        e2eId: `${e2ePrefix}-apps-store`,
        url: composeUrl(adminHost, appStorePath),
        onClick: appStorePath
          ? (event) => {
              event.preventDefault();
              redirectToInnerPath(appStorePath);
            }
          : noop,
      },
      {
        id: t('Shopback'),
        label: t('Shopback'),
        e2eId: `${e2ePrefix}-shopback`,
      },
    ],
    [e2ePrefix, t, redirectToInnerPath, adminHost, appStorePath],
  );

  useEffect(() => {
    requestAdminHost();
    requestAppStorePath();
  }, [requestAdminHost, requestAppStorePath]);

  return breadcrumbs;
};

export default useBreadcrumb;
