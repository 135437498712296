import { useTranslation } from 'react-i18next';
import { AnchorLink } from '@shopline/dashboard-ui';
import {
  Grid,
  Page,
  PageDescription,
  PageTitle,
  Section,
  SectionCaption,
} from './components';
import useBreadcrumb from './useBreadcrumb';
import useNav from './useNav';
import { useQueryShopbackConfig } from './useShopbackConfig';
import withLoading from './withLoading';
import ShopbackConfigForm from './ShopbackConfigForm';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

const EnhancedShopbackConfigForm = withLoading(ShopbackConfigForm);

const Main = () => {
  const { t } = useTranslation(['main', 'pageTitle']);
  const {
    items,
    refs: { formSectionRef },
  } = useNav({ e2ePrefix: `${E2E_PREFIX}-use-nav` });

  const breadcrumbs = useBreadcrumb({
    e2ePrefix: `${E2E_PREFIX}-use-breadcrumb`,
  });

  const { isSuccess, data: shopbackConfig } = useQueryShopbackConfig();

  return (
    <Page
      breadcrumbs={breadcrumbs}
      titleLeftSection={
        <PageTitle>{t('Shopback', { ns: 'pageTitle' })}</PageTitle>
      }
    >
      <PageDescription>{t('App Description')}</PageDescription>
      <Grid.Row>
        <Grid.Column disableGutter lg={8} md={12} sd={12}>
          <Section
            title={t('Connect Shopback Cashback')}
            innerRef={formSectionRef}
          >
            <SectionCaption>{t('Connect Shopback Caption')}</SectionCaption>
            <EnhancedShopbackConfigForm
              loading={!isSuccess}
              shopbackConfig={shopbackConfig}
            />
          </Section>
        </Grid.Column>
        <Grid.Column disableGutter lg={4} md={12} sd={12}>
          <AnchorLink
            scrollbarWrapper={document.getElementsByTagName('html')[0]}
            items={items}
          />
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
};

export default Main;
