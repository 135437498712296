const main = {
  'App Description':
    '串接成功后，SHOPLINE 系统将会纪录功您在 SHOPBACK上的商店连结所引导过来顾客的订单。在顾客结帐时，SHOPLINE 会自动将订购资讯回传给 SHOPBACK，订单鉴赏期过后，SHOPLINE 会再次回报最终结算金额给 SHOPBACK 作为现金回馈的依据。',
  'Connect Shopback Cashback': '串接 SHOPBACK 商店导购',
  'Connect Shopback Caption': '请与你的 SHOPBACK 窗口联络，取得以下资讯 ',
  'Order offer ID': '订单 offer 代号',
  'Validation offer ID': '验证 offer 代号',
  'provided by Shopback': '{{ value }} (由SHOPBACK提供)',
  'Setting has been saved successfully': '已成功储存设定',
  'Please enter an valid ID': '请输入有效代号',
};

export default main;
