import { Button } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { Field, FieldRow, FormFooter, Input } from './components';
import useShopbackConfigForm from './useShopbackConfigForm';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-shopback-config-form`;

const ShopbackConfigForm = ({ shopbackConfig }) => {
  const { t } = useTranslation(['main', 'common']);
  const [
    { orderOfferId, validationOfferId },
    { errors, handlers, handleSubmit, hasErrors, touched },
    { renderMessages, submitting },
  ] = useShopbackConfigForm({
    defaultValues: shopbackConfig,
  });
  return (
    <>
      <FieldRow>
        <Field
          title={t('provided by Shopback', { value: t('Order offer ID') })}
        >
          <Input
            block
            disabled={submitting}
            value={orderOfferId}
            status={errors.orderOfferId !== '' ? 'invalid' : 'valid'}
            renderMessages={renderMessages.orderOfferId}
            e2eInputId={`${E2E_PREFIX}-order-offer-id-input`}
            e2eMessagesId={`${E2E_PREFIX}-order-offer-id-errors`}
            {...handlers.orderOfferId}
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          title={t('provided by Shopback', { value: t('Validation offer ID') })}
        >
          <Input
            block
            disabled={submitting}
            value={validationOfferId}
            status={errors.validationOfferId !== '' ? 'invalid' : 'valid'}
            renderMessages={renderMessages.validationOfferId}
            e2eInputId={`${E2E_PREFIX}-validation-offer-id-input`}
            e2eMessagesId={`${E2E_PREFIX}-validation-offer-id-errors`}
            {...handlers.validationOfferId}
          />
        </Field>
      </FieldRow>
      <FormFooter>
        <Button.Primary
          width="WIDE"
          e2eId={`${E2E_PREFIX}-save-btn`}
          onClick={handleSubmit}
          disabled={!touched || hasErrors || submitting}
        >
          {t('Save', { ns: 'common' })}
        </Button.Primary>
      </FormFooter>
    </>
  );
};

export default ShopbackConfigForm;
